import axios from "axios";
import sessionManager from "./sessionManager";


const client = axios.create({
    //baseURL:'https://localhost:7164/api/'
    baseURL:'https://api.posgon.livinihome.com/api/'

})

class request{
    getAxios(url){
        return client.get(url,{headers:{'Authorization': 'Bearer ' + sessionManager.getToken().token}}); 
    } 
    loginAxios(url, data){
        return client.post(url, data); 
    } 
    postAxios(url, data, content){
        return client.post(url, data,{headers:{'Authorization': 'Bearer ' + sessionManager.getToken().token, 
        content}});
    }
    deleteAxios(url){
        return client.delete(url,{headers:{'Authorization': 'Bearer ' + sessionManager.getToken().token}});
    }
    putAxios(url, data){
        return client.put(url, data,{headers:{'Authorization': 'Bearer ' + sessionManager.getToken().token}});
    }
}

export default new request;